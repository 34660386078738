<template>
  <div class="physicalExamination h-100">
    <div class="container h-100 py-5">
      <h3
        class="text-capitalize pt-2 pt-md-5"
        data-aos="fade-down-right"
        data-aos-easing="ease-in-out"
        data-aos-duration="400"
      >
        {{ this.physical_examination.Label }}
      </h3>
      <div
        class="row row-wrapper d-flex align-items-center justify-content-center"
      >
        <div class="row ">
          <div class="row pt-3 pb-4">
            <div
              class="col-12 col-xl-12 col-xs-12 pb-2 pb-md-5"
              data-aos="fade-right"
              data-aos-easing="ease-in-out"
              data-aos-duration="600"
            >
              <div class="row">
                <div
                  class="col-6 col-lg-3 col-md-3 col-sm-4 text-center my-3"
                  v-for="(items, system) in physical_examination.systems"
                  :key="system"
                >
                  <div
                    class="
                      d-flex
                      flex-wrap
                      align-items-center
                      justify-content-center
                      px-3
                      cursor-pointer
                    "
                    @click="openModal(items, (system.split('|'))[1])"
                  >
                    <div class="symptoms text-center my-auto w-100 ">
                      <img :src="baseURL+'/'+ (system.split('|'))[2]" alt="" class="100 system" />
                    </div>
                    <div>
                      <p class="text-muted mt-3">{{ (system.split('|'))[1] }}</p>
                      <!-- <p class="text-muted mt-2">{{ system }}</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="staticBackdropLabel"></h4>
          </div>
          <div class="modal-body" id="modal_body">
            <div class="row">
              <div :class=" temp.length > 1 ? 'col-md-6 py-2': 'col-md-12 d-flex justify-content-center py-2'" v-for="symptom in this.temp" :key="symptom.id">
                <div class="card shadow p-3 text-center" >
                  <h5>{{symptom.value}}</h5>
                  <div class="py-3" v-if="symptom.img_url != null" >
                    <img :src="this.baseURL+'/'+ symptom.img_url" class="w-100" alt="">
                  </div>
                  <div class="py-3" v-if="symptom.audio_url != null">
                    <audio controls class="text-muted"> <source :src="this.baseURL+'/'+ symptom.audio_url" type="audio/mpeg"> </audio>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="close()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
export default {
  name: "Physical Examination",
  data() {
    return {
      physical_examination: [],
      baseURL: null,
      temp:[],
      system: {
        img_url: null,
      },
    };
  },
  methods: {
    getPhysicalExamination() {
      this.physical_examination = this.$store.getters["physicalExamination"];
    },
    openModal(symptoms, system_name) {
      var title = `<p class='text-capitalize'>${system_name}</p>`;
      $("#staticBackdropLabel").html(title);
      
      symptoms.forEach(symptom => {
        this.temp.push(symptom)
      });
      
      $("#staticBackdrop").modal("show");

    },
    spliteString(string) {
      return  
    },
    close(){
      this.temp = []
    }
  },
  mounted() {
    this.getPhysicalExamination();
    this.baseURL = axios.defaults.baseURL;
    this.$store.dispatch( 'setInstructions', this.$store.getters['physicalExamination'].instructions )
    this.$store.dispatch( 'setStartTime')
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch( 'setEndTime' , this.$options.name)
    next()
  }
};
</script>

<style scoped>
/* .patient > img{
    max-width: 350px;
    width: 100%;
} */
.symptoms > img {
  max-width: 150px;
  width: 100%;
}
.system {
  transition: transform .2s; /* Animation */
}
.system:hover {
  transform: scale(1.2);
  -webkit-filter: drop-shadow(5px 5px 5px #666666);
  filter: drop-shadow(0px 0px 15px #48D8D8);
}
.card{
  max-width: 375px;
}
@media (max-width: 992px) {
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
}
</style>